<template>
  <el-dialog
    custom-class="ql-dialog fit-width-content"
    :title="isEdit ? '编辑班级' : '新增班级'"
    :visible.sync="visible"
    width="578px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="83px">
      <el-form-item label="班级名称" prop="className" size="small">
        <el-input v-model.trim="formData.className" :style="{ width: '400px' }"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="handleCancel" size="small">取消</el-button>
      <el-button type="primary" size="small" :loading="loading" v-debounceClick="handleSave">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Form, FormItem, Input, Select, Option } from 'element-ui';
import { addClass, updateClass } from '@/api/basic';

export default {
  name: 'ClassDialog',

  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
    teacherOptions: Array,
  },

  data() {
    const validateClassName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('班级名称不能为空'));
        return;
      } else if (value.length > 25) {
        callback(new Error('班级名称不能超过25位'));
        return;
      }
      callback();
    };

    return {
      loading: false,
      rules: {
        className: [
          {
            validator: validateClassName,
            trigger: 'blur',
            required: true,
          },
        ],
      },
      formData: {
        lessonId: null,
        subjectName: '',
      },
    };
  },

  methods: {
    handleSave() {
      this.loading = true;

      this.$refs.form.validate((valid) => {
        if (valid) {
          // 新文档要求的
          if (!this.isEdit) {
            addClass({
              className: this.formData.className,
              gradeId: this.formData.gradeId,
              teacherId: this.formData.teacherId,
              upgradePlanId: this.$route.query.upgradePlanId,
            })
              .then((res) => {
                if (res && res.result == 0) {
                  this.$message({
                    showClose: true,
                    message: '新增班级成功',
                    type: 'success',
                  });
                  this.$emit('success', this.formData);
                }
              })
              .catch(() => (this.loading = false));
          } else {
            updateClass({
              className: this.formData.className,
              classId: this.formData.classId,
              upgradePlanId: this.$route.query.upgradePlanId,
            })
              .then((res) => {
                if (res && res.status === 0) {
                  this.$message({
                    showClose: true,
                    message: '编辑班级成功',
                    type: 'success',
                  });
                  this.$emit('success', this.formData);
                }
              })
              .catch(() => (this.loading = false));
          }

          this.$emit('success', this.formData);
        } else {
          this.loading = false;
          return false;
        }
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },
  },

  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.classDialog {
}
</style>
