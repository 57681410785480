<template>
  <div class="delclass">
    <el-dialog
      custom-class="ql-dialog delClassDialog"
      title="删除班级"
      :visible.sync="delClassDialogVisible"
      @close="dialogClose"
      width="500px"
      v-if="delClassDialogVisible"
    >
      <!-- 警告标识 -->
      <div class="warning">
        <i class="el-icon-warning" style="color: #ffbb2c"></i>
      </div>
      <div class="text-F2 text-center">{{ message }}</div>
      <div class="dialogFooter">
        <span slot="footer">
          <EButton type="btn-ghost-danger" @click.once="handleConfirm">删除</EButton>
          <EButton @click="dialogClose">取消</EButton>
        </span>
      </div>
    </el-dialog>
    <messageDialog :messageFlag.sync="messageFlag" @messageClose="messageClose"></messageDialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import { deleteClass } from '@/api/basic';
import EButton from '@/components/button/index.vue';

import messageDialog from './messageDialog.vue';

export default {
  name: 'DelClassDialog',

  data() {
    return {
      delClassDialogVisible: this.delClassDialogFlag,
      messageFlag: false,
    };
  },

  props: {
    delClassDialogFlag: {
      type: Boolean,
    },
    message: {
      type: String,
    },
    classId: {
      type: String,
    },
  },

  components: {
    [Dialog.name]: Dialog,
    messageDialog,
    EButton,
  },

  methods: {
    dialogClose() {
      this.$emit('update:delClassDialogFlag', false);
    },

    handleConfirm() {
      // this.$emit('delClassConfirm');
      // this.dialogClose();
      deleteClass({
        classId: this.classId,
        upgradePlanId: this.$route.query.upgradePlanId,
      })
        .then((res) => {
          if (res.status === 0) {
            this.$emit('delClassConfirm');
            this.dialogClose();
          } else {
            this.messageFlag = true;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
      //
      // this.messageFlag = true;
    },

    messageClose() {
      this.dialogClose();
    },
  },

  watch: {
    delClassDialogFlag() {
      this.delClassDialogVisible = this.delClassDialogFlag;
    },
  },
};
</script>

<style lang="scss" scoped src="../index.scss"></style>
