<template>
  <div>
    <el-dialog
      custom-class="ql-dialog studentListDialog"
      :title="tableTitle"
      :visible.sync="dialogTableVisible"
      @close="dialogClose"
      width="896px"
    >
      <div class="mb-16px flex justify-between items-center">
        <span class="mr-54px">共{{ gridData.length }}人</span>

        <div>
          <searchInput
            v-if="dialogTableVisible"
            v-model="filterOptions.searchKey"
            width="264px"
            placeholder="按学生姓名/账号/学号查找"
          />

          <span class="text-A8 mx-12px">|</span>
          <!--        <EButton @click="handleExportBindPen"> 导出绑笔名单 </EButton>-->
          <EButton :disabled="!selectedRows.length" @click="handleBatchTransfer"> 批量转班 </EButton>
          <EButton :disabled="!selectedRows.length" type="btn-ghost-danger" @click="handleDel"> 批量删除 </EButton>
          <EButton type="btn-fill-primary" @click="handleAddStudent">
            <i class="el-icon-plus mg-r-4" />新增学生
          </EButton>
        </div>
      </div>

      <ETable
        :data="gridData"
        row-key="stuId"
        :columns="columns"
        :selectedRows.sync="selectedRows"
        @selectAll="getAllTableData"
        :height="450"
      />

      <span slot="footer">
        <EButton @click="dialogClose">取消</EButton>
        <EButton type="btn-fill-primary" @click="dialogClose">确定</EButton>
      </span>
    </el-dialog>

    <!-- 新的弹窗 -->
    <transferClassDialog
      :transferDialogFlag.sync="transferDialogFlag"
      @transferStudent="transferStudentConfirm"
      :gradeId="gradeId"
      :gradeList="gradeList"
      :operate="operate"
      :userId="userId"
      :userIds="userIds"
      :studentName="studentName"
      :classId="classId"
    />

    <addStudentDialog
      :addStudentDialogFlag.sync="addStudentDialogFlag"
      @addStudent="addStudentConfirm"
      :classId="classId"
    />
  </div>
</template>

<script>
import { getClassUserList, deleteClassUser, addNewClassUser, changeClassUser } from '@/api/basic';

import { Button, Dialog, Table, TableColumn } from 'element-ui';

import transferClassDialog from './transferClassDialog.vue';
import addStudentDialog from './addStudentDialog.vue';
import searchInput from '@/components/searchInput/index.vue';
import ETable from '@components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';
import EButton from '@components/button/index.vue';

export default {
  name: 'StudentListDialog',

  components: {
    ETable,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    transferClassDialog,
    addStudentDialog,
    searchInput,
    // editInnerNoDialog,
    EButton,
  },

  props: {
    gradeName: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    gradeId: [String, Number],
    classId: {
      type: String,
    },
    dialogFlag: {
      type: Boolean,
    },
    gradeList: {
      type: Array,
    },
  },

  data() {
    return {
      dialogTableVisible: false,
      gridData: [],
      transferDialogFlag: false,
      addStudentDialogFlag: false,
      editDialogFlag: false,
      //对表格的操作
      operate: '',
      //要操作的学生学号
      userId: '', // 单个学生转班/删除
      userIds: [], // 批量转班/删除
      studentName: '',
      searchKey: '',

      selectedRows: [],
      filterOptions: {},
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.pageChange,
      },
    };
  },

  methods: {
    handleDel(val) {
      // 若val有值则是删除单个，否则是批量删除
      let userName;
      if (val) {
        userName = `确定删除该班级中的"${val.stuName}"吗？`;
      } else {
        userName = '确定删除该班级选中的学生吗？';
      }

      let mes = `${userName}`;

      const h = this.$createElement;
      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-13' }, '确定删除该班级中选中的学生吗？'),
          h('p', { class: 'T5-2' }, mes),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
      })
        .then(() => {
          const config = {
            classId: this.classId,
            stuIds: val ? [val?.stuId] : this.selectedRows.map((item) => item.stuId),
            upgradePlanId: this.$route.query.upgradePlanId,
          };

          deleteClassUser(config).then((res) => {
            if (res.status === 0) {
              this.$message({
                showClose: true,
                message: '删除学生成功',
                type: 'success',
              });

              if (!val) {
                this.selectedRows = [];
              }

              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    dialogClose() {
      this.filterOptions = {};
      this.selectedRows = [];
      this.$emit('update:dialogFlag', false);
    },

    // 个人转班
    handleTransfer(val) {
      if (this.notAllowOperate) return;

      this.operate = 'single';
      this.userIds = [val.stuId];
      this.studentName = `${val.stuName}-`;
      this.transferDialogFlag = true;
    },

    // 个人编辑
    handleEdit(val) {
      if (this.notAllowOperate) return;

      this.userId = val.userId;
      this.studentName = val.stuName;
      this.editDialogFlag = true;
    },
    // 导出绑笔名单
    handleExportBindPen() {
      this.$emit('exportBindPenUser', this.gradeId, this.classId);
    },

    // 批量转班
    handleBatchTransfer() {
      this.operate = 'multiple';
      this.studentName = '批量';
      this.userIds = this.selectedRows.map((item) => item.stuId);
      this.transferDialogFlag = true;
    },

    //新增学生
    handleAddStudent() {
      if (this.notAllowOperate) return;
      this.addStudentDialogFlag = true;
    },

    //新增学生确认了 (可能要整个删除)
    addStudentConfirm(val) {
      addNewClassUser({
        classId: val.classId,
        stuId: val.stuId,
        upgradePlanId: this.$route.query.upgradePlanId,
      }).then((res) => {
        if (res.status === 0) {
          this.$message({
            showClose: true,
            message: '新增学生成功',
            type: 'success',
          });
          this.getTableData();
        }
      });
    },

    //转班确认了  (可能要整个删除)
    transferStudentConfirm(val) {
      const { oldClassId, newClassId, userId, userIds } = val;

      changeClassUser({
        oldClassId,
        newClassId,
        stuIds: userId ? [userId] : userIds,
        upgradePlanId: this.$route.query.upgradePlanId,
      })
        .then((res) => {
          if (res.status === 0) {
            this.$message({
              showClose: true,
              message: '学生转班成功',
              type: 'success',
            });

            this.selectedRows = [];
            this.getTableData();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    async getTableData() {
      const res = await getClassUserList({
        classId: this.classId,
        pageIndex: 1,
        pageSize: 9999999,
        schoolYearId: this.$route.query.schoolYearId,
        upgradePlanId: this.$route.query.upgradePlanId,
        ...this.filterOptions,
      });

      if (res.status === 0) {
        this.gridData = res.result;
      }
    },

    getAllTableData() {
      this.selectedRows = this.gridData;
    },
  },

  computed: {
    tableTitle() {
      return `${this.gradeName}${this.className}学生名单`;
    },

    userInfo() {
      return this.$store.getters.userInfo;
    },

    notAllowOperate() {
      // return this.userInfo.thirdpartySchoolProjectCode !== '';
      return false;
    },

    columns() {
      return [
        {
          label: '账号',
          prop: 'loginName',
        },
        {
          label: '姓名',
          prop: 'stuName',
        },
        {
          label: '学号',
          prop: 'idNumber',
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '转班',
                disabled: () => this.notAllowOperate,
                handle: this.handleTransfer,
              },
              {
                text: '删除',
                disabled: () => this.notAllowOperate,
                handle: this.handleDel,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
  },

  watch: {
    dialogFlag() {
      this.dialogTableVisible = this.dialogFlag;
      if (this.dialogTableVisible && this.classId) {
        this.getTableData();
      }
    },

    filterOptions: {
      handler() {
        this.getTableData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped src="../index.scss"></style>
