<template>
  <div>
    <el-dialog
      custom-class="ql-dialog fit-width-content"
      :title="dialogTitle"
      :visible.sync="transferDialogVisible"
      @close="dialogClose"
    >
      <div class="transferTo">
        <span>转班至</span>
      </div>

      <el-form ref="form" :model="formData" label-width="45px">
        <el-form-item label="年级" prop="grade" size="small">
          <el-select v-model="formData.gradeId" placeholder="请选择年级" style="width: 400px">
            <el-option v-for="item in gradeList" :key="item.value" :label="item.text" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="班级" prop="class" size="small">
          <el-select v-model="formData.classId" placeholder="请选择班级" style="width: 400px">
            <el-option v-for="item in classOptions" :key="item.classId" :label="item.className" :value="item.classId" />
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <EButton @click="handleCancel">取消</EButton>
        <EButton type="btn-fill-primary" @click="handleConfirm" :disabled="!formData.gradeId || !formData.classId"
          >确定</EButton
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClassUserListData } from '@/api/basic';

import { Button, Dialog, Form, FormItem, Select, Option } from 'element-ui';
import EButton from '@components/button/index.vue';

export default {
  name: 'TransferClassDialog',

  data() {
    return {
      transferDialogVisible: false,
      formData: {
        gradeId: '',
        classId: '',
      },
      classOptions: [],
    };
  },

  props: {
    transferDialogFlag: {
      type: Boolean,
    },
    gradeId: {
      type: String,
    },
    gradeList: {
      type: Array,
    },
    studentName: {
      type: String,
    },
    operate: {
      type: String,
    },
    userId: {
      type: String,
    },
    userIds: {
      type: Array,
    },
    classId: {
      type: String,
    },
  },

  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    EButton,
  },

  methods: {
    dialogClose() {
      this.formData.gradeId = '';
      this.formData.classId = '';
      this.classOptions = [];
      this.$emit('update:transferDialogFlag', false);
    },

    handleCancel() {
      this.dialogClose();
    },

    handleConfirm() {
      let gradeName = this.gradeList.filter((item) => item.value == this.formData.gradeId)[0].text;
      let className = this.classOptions.filter((item) => item.classId == this.formData.classId)[0].className;
      let mes = `确定将${this.userIds.length > 1 ? '所选学生' : '该学生'}转入${gradeName}-${className}吗？`;

      this.$msgbox
        .confirm(mes, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => {
          this.$emit('transferStudent', {
            oldClassId: this.classId,
            newClassId: this.formData.classId,
            operate: this.operate,
            userId: this.userId,
            userIds: this.userIds,
          });
          this.dialogClose();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消转班',
          });
        });
    },

    selectGradeChange(val) {
      this.classOptions = [];
      this.formData.classId = '';

      let config = {
        gradeId: val,
        stuIds: this.userIds || [this.userId],
        schoolYearId: this.$route.query.schoolYearId,
      };

      getClassUserListData(config).then((res) => {
        this.classOptions = res.result;
      });
    },
  },

  watch: {
    transferDialogFlag() {
      this.transferDialogVisible = this.transferDialogFlag;
      this.formData.gradeId = this.gradeId;

      if (this.transferDialogVisible && this.formData.gradeId) {
        this.selectGradeChange(this.formData.gradeId);
      }
    },

    'formData.gradeId'(val) {
      this.selectGradeChange(val);
    },
  },

  computed: {
    dialogTitle() {
      return this.studentName + '转班';
    },
  },
};
</script>

<style lang="scss" scoped>
.transferTo {
  margin-bottom: 15px;
  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303333;
  }
}
</style>
